import React, { Fragment, useEffect, useRef, useState, useCallback, useReducer } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { isEmpty, isDate, get } from "lodash";
import isURL from "validator/lib/isURL";
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Cookies } from "react-cookie";
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

import { makeStyles } from "@material-ui/core/styles/index";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  Tooltip,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import {
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  FileCopy as FileCopyIcon,
  OpenInNew as OpenInNewIcon,
} from "@material-ui/icons";

import { DatePicker } from "@material-ui/pickers";

import YesNoPopup from '../components/popups/YesNoPopup'
import ColorInputField from "../components/inputs/ColorInputField";
import DfmDefectsExtractionAccordion from "../components/accordions/DfmDefectsExtractionAccordion.jsx";
import DragDrop from "../components/DragDrop";
import ExtractButton from "../components/buttons/ExtractButton.jsx";
import FtrAccordion from "../components/ftr-components/FtrAccordion.jsx";
import { FtrBoldText, FtrNormalText } from '../components/ftr-components';
import IOSSwitch from "../components/IOSSwitch";
import LoadingBackDropText from "../components/LoadingBackDropText";
import MaterialCategoriesInputField from "../components/inputs/MaterialCategoriesInputField";
import OtherMaterialField from "../components/forms/form-fields/OtherMaterialField";
import OtherSurfaceFinishingField from "../components/forms/form-fields/OtherSurfaceFinishingField";
import OutlinedDiv from "../components/panels/OutlinedDiv";
import QcReportsDisplay from "../components/info/QcReportsDisplay";
import CheckAndWarningInfo from '../components/info/CheckAndWarningInfo';
import QualityChecks from "../components/fields/QualityCheck.jsx";
import SearchInputDropdown from "../components/dropdowns/SearchInputDropdown";
import SurfaceFinishingField from "../components/inputs/SurfaceFinishingField";
import TechnologyInputField from "../components/inputs/TechnologyInputField";
import ThreeDInfillFieldSelect from "../components/forms/form-fields/ThreeDInfillFieldSelect";
import ThreeDLayerThicknessFieldSelect from "../components/forms/form-fields/ThreeDLayerThicknessFieldSelect";
import ThreeDPrintingMaterialField from "../components/inputs/ThreeDPrintingMaterialField";
import ThreeDPrintingTechnologyInputField from "../components/inputs/ThreeDPrintingTechnologyInputField";
import { FlexColumn, FlexRow, FlexRowEnd } from '../components/layouts/FlexLayouts.jsx';
import { WhiteOnRedTooltip } from "../components/CustomizedTooltips";
import ImageWith3DViewer from "../components/images/ImageWith3DViewer.jsx";
import { RepeatOrderIcon } from "../components/icons/ItemIcon.jsx";

import { editItemDataPoint } from "../apis/quotationApi.js";
import { getAllBuyers } from "../apis/userApi.js";
import { getDfmExtractDimensions } from "../apis/dfmApi.js";
import { updatePpeInformationItem, adminItemStatusUpdate, getItemDetailsApi } from "../apis/itemApi";
import { getSurfaceFinishingOptionsForSupplier } from "../apis/configurationApi";
import { pdfFlatten } from "../apis/pdfApi";
import {
  getLatestTechnicalDrawingExtractionAttemptById,
  extractTechnicalDrawingByIdAndPdfUrl,
} from '../apis/technicalDrawingExtractionApi';

import { useItemInputConfig } from "../hooks/useItemInputConfig";

import {
  adminEditItem,
  deletePart,
} from "../actions";

import { join } from "../utils/arrayUtils.js";
import {
  getCancelledItemInfo,
  getPpeInfo,
  getPpeUpdateHashcodeStatusText,
  getQualityProperties,
  getQualityPropertiesToUpdate,
  is3DPTechnology,
  shouldShowInvoice,
} from "../utils/itemUtils";

import { handleCopyText, handleOpenTrackingLink } from '../utils/shipmentUtils';
import { convertMetricToImperial, convertImperialToMetric } from "../utils/userUtils";
import { dateTzSingapore, getDateStr } from "../utils/dateTimeUtils";
import { downloadS3File, getUrlFileBySupportedFileTypes, isPdfFile } from '../utils/fileUtils';
import { getFileNameFromCadFile } from '../utils/itemUtils';
import { isAdminOrHigherRole } from "../utils/roleUtils";
import { isEmptyValue } from "../utils/commonUtils";
import { getPpeMaterialCode } from "../utils/ppeUtils.js";
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from "../utils/inputUtils";
import {
  getMarginalizedPrice
} from "../utils/marginUtils"
import { updateTdeDataFromState } from '../utils/tdeUtils';

import { notifySuccess, notifyError } from "../services/notificationService";
import {
  getCadPartS3Key,
  getImageFileS3Key,
  uploadFileToS3,
  uploadPublicFileToS3,
} from '../services/s3Service';
import {
  DISPLAY_UNIT_DROPDOWN,
  UNIT_TYPES,
  UNIT_OPTIONS
} from "../constants/unitConstants";
import {
  ITEM_STAGE_TYPE,
  USER_AGENT_INFO_KEY,
  ITEM_STAGES,
  ROLE_TYPES,
  S3BUCKET_PDF_IMAGES_FOLDER,
  paymentStatuses
} from "../constants";
import {
  OUTPUT_TO_NON_MATCHED_FIELDS_MAPPING,
  OUTPUT_TO_MATCHED_FIELDS_MAPPING,
  HIDDEN_FIELDS,
  TDE_LOCATIONS,
  TDE_RESPONSE_KEYS,
  CHECK_TDE_RESPONSE_VALUES,
  MATCHED_TDE_OUTPUT_MAPPING
} from "../constants/technicalDrawingExtractorConstants";
import {
  TECHNOLOGY_OPTION_TYPE,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  THREE_D_P_FDM_TECH,
  THREE_D_P_OLD_SLA_VALUE_TECH,
  THREE_D_P_SLA_TECH,
  THREE_D_P_TECH_ABBRE_MAPPING,
} from "../constants/NewPartConstants";
import { FEATURE_FLAG_HUBSPOT } from "../constants/featureFlagConstants";
import { DELIVERY_OPTIONS_DISPLAY_MAPPING, ITEM_CANCELLATION_REASON, ADMIN_UPDATE_ACTION_TYPES } from "../constants/itemConstants";
import { SHIPMENT_TRACKING_LINKS } from '../constants/orderReadyConstants.js';
import { techMapping } from "../constants/PPEConstants.js";

import { SHIPMENT_PROVIDERS } from '../constants/orderReadyConstants';

import { colors } from "../palette.js";


// ---------------------------------------------------------------------------------

const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  media: {
    height: 160
  },
  inputLabel: {
    fontSize: '14px', // Adjust the font size as needed
  },
  image: {
    maxWidth: 300,
    maxHeight: 300,
    minWidth: 250,
    minHeight: 250,
    border: "1px solid #d8d8d8",
  },
  img: {
    maxWidth: "250px",
    maxHeight: "250px"
  },
  property: {
    margin: "25px 0",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    display: "flex",
    justifyContent: "space-between"
  },
  reworkDeliveryDate: {
    '& .Mui-error': {
      color: 'blue',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'blue',
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center',
  },
  renderImage: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
  },
  switchBlue: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.buttonBlue
    }
  },
  switchRed: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.buttonRed
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancellationInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: 'bold',
    wordBreak: 'break-word'
  },
  greenText: {
    '& .MuiOutlinedInput-input': {
      color: colors.successGreen
    }
  },
  fullHeight: {
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiFormControl-root': {
      height: '100%',
    }
  },
  warningCheckIcon: {
    marginLeft: 0,
  }
}));

const itemStatus = ITEM_STAGES.map((stage, index) => {
  return {
    value: index,
    label: stage
  };
});

const paymentStatus = paymentStatuses.map((status) => {
  return {
    value: status.toLowerCase(),
    label: status,
  };
});

export function EditItem(props) {
  const classes = useStyles();

  const pathParams = useParams();

  const {
    isDialog,
    submitEditPart,
    submitDeletePart,
    role,
    myUserId,
    itemID,
  } = props;

  const editItemID = itemID || pathParams.itemID;

  const [item, setItem] = useState(null);
  const [openDeletePartPopup, setOpenDeletePartPopup] = useState(false);
  const cafFileUploadRef = useRef(null);
  const otherTechnologyRef = useRef(null);
  const otherMaterialFieldRef = useRef(null);
  const otherSurfaceFinishFieldRef = useRef(null);
  const threeDTechFieldRef = useRef(null);
  const invoiceUrlFieldRef = useRef(null);
  const selectBuyersRef = useRef(null);
  const reworkDeliveryDateFieldRef = useRef(null);
  const otherShipmentProviderRef = useRef(null);
  const sizeXRef = useRef(null);
  const sizeYRef = useRef(null);
  const sizeZRef = useRef(null);
  const imageOkCheckboxRef = useRef(null);
  const shipmentTrackingNumberRef = useRef(null);

  // set original states from item details page
  const [namePresent, setNamePresent] = useState(true);
  const [qtyPresent, setQtyPresent] = useState(true);
  const [editName, setEditName] = useState("");
  const [editReferenceName, setEditReferenceName] = useState("");
  const [editStatus, setEditStatus] = useState(itemStatus[0].value);
  const [cancellationReason, setCancellationReason] = useState("");
  const [editPaymentStatus, setEditPaymentStatus] = useState(
    paymentStatus[0].value
  );
  const [requiredRework, setRequiredRework] = useState("");
  const [editQuantity, setEditQuantity] = useState(0);
  const [technology, setTechnology] = useState("");
  const [otherTechnology, setOtherTechnology] = useState('');
  const [threeDTechnology, setThreeDTechnology] = useState('');
  const [threeDInfill, setThreeDInfill] = useState('');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState('');
  const [editDeadline, setEditDeadline] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [dateOfExpiry, setDateOfExpiry] = useState(null);
  const [editDeliveryDate, setEditDeliveryDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editCollectionDate, setEditCollectionDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editRevisedCollectionDate, setEditRevisedCollectionDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editReworkDeliveryDate, setEditReworkDeliveryDate] = useState(null);
  const [collectionDate, setCollectionDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editActualCollectionDate, setEditActualCollectionDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editActualDeliveryDate, setEditActualDeliveryDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editRevisedDeliveryDate, setEditRevisedDeliveryDate] = useState(
    new Date(Date.now() + 12096e5)
  );
  const [editRegeneratePO, setEditRegeneratePO] = useState(false);
  const [editDescription, setEditDescription] = useState("");
  const [surfaceFinish, setSurfaceFinish] = useState("");
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');
  const [editPartApplication, setEditPartApplication] = useState("");
  const [editTolerance, setEditTolerance] = useState("");
  const [displayedTolerance, setDisplayedTolerance] = useState("");
  const [editCadFile, setEditCadFile] = useState([]);
  const [editImageFile, setEditImageFile] = useState("");
  const [currentImg, setCurrentImg] = useState(
    "https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder"
  );
  const [material, setMaterial] = useState("");
  const [otherMaterial, setOtherMaterial] = useState('');
  const [color, setColor] = useState("");
  const [editExpectedPrice, setEditExpectedPrice] = useState("");
  const [editTargetPriceSupplier, setEditTargetPriceSupplier] = useState("");
  const [editActualDeliveryCost, setEditActualDeliveryCost] = useState("");
  const [editDatePosted, setEditDatePosted] = useState("");
  const [editDeliveryPreference, setEditDeliveryPreference] = useState(
    "on_premise"
  );
  const [editUserID, setEditUserID] = useState(myUserId);
  const [editPrice, setEditPrice] = useState("");
  const [editPpeMarkdown, setEditPpeMarkdown] = useState("");
  const [editOriginalFiles, setEditOriginalFiles] = useState([]);
  const [verifiedDate, setVerifiedDate] = useState("");
  const [allBuyers, setAllBuyers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [hubspotDealID, setHubspotDealID] = useState('');
  const [qcReports, setQcReports] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState('N.A');
  const [formError, setFormError] = useState({});
  const [materialColor, setMaterialColor] = useState('');
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [itemHashcodeCad, setItemHashcodeCad] = useState('');
  const [itemHashcodePdf, setItemHashcodePdf] = useState('');
  const [refPartID, setRefPartID] = useState('');
  const [refQuoteID, setRefQuoteID] = useState('');
  const [requestSameFabricator, setRequestSameFabricator] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [editRefetching, setEditRefetching] = useState(false);
  const [warningDialog, setWarningDialog] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [dfmInput, setDfmInput] = useState('');
  const [ppeDataPoint, setPpeDataPoint] = useState(false);
  const [biDataPoint, setBiDataPoint] = useState(false);
  const [clarifying, setClarifying] = useState(false);
  const [dataPointLoaded, setDataPointLoaded] = useState(false)
  const [flattenPdf, setFlattenPdf] = useState(true);
  const [itemMeasurementUnit, setItemMeasurementUnit] = useState(UNIT_TYPES.METRIC);
  const [isDimensionDataRequired, setIsDimensionDataRequired] = useState(true);
  const [isExtractedMaterialPlastic, setIsExtractedMaterialPlastic] = useState(false);

  const [ppeInformation, updatePpeInformation] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      boundingBoxX: null,
      boundingBoxY: null,
      boundingBoxZ: null,
      volume: null,
      weight: null,
      surfaceArea: null,
      expandedAccordion: isDialog,
      showRegeneratePpePopup: false,
      dfm: null,
      fetchLoading: false,
      saveLoading: false,
    }
  );
  const [shipmentInformation, updateShipmentInformation] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      shipmentProvider: null,
      otherShipmentProvider: null,
      shipmentTrackingNumber: null,
      shipmentDate: null,
      expandedAccordion: false,
    }
  );
  const [qualityInformation, updateQualityInformation] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      partnerDimQuality: null,
      partnerSFQuality: null,
      partnerVisualQuality: null,
      partnerPackagingQuality: null,
      customerDimQuality: null,
      customerSFQuality: null,
      customerVisualQuality: null,
      customerPackagingQuality: null,
    }
  );

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setSurfaceFinishOptions,
    }
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const {
    status: loadingStatus,
    data: itemDetails,
    refetch: editItemRefetch,
  } = useQuery([
    'getItemDetails',
    editItemID,
  ],
    () => getItemDetailsApi(editItemID)
  );

  const [imageOkConfirmation, setImageOkConfirmation] = useState(false);

  const isDimensionDataFieldRequired = isDimensionDataRequired && isDialog;
  const showImageConfirmationCheckbox = currentImg
    && currentImg.startsWith('http')
    && currentImg.includes(S3BUCKET_PDF_IMAGES_FOLDER);

  const populateItemDetails = useCallback(async (itemDetails) => {
    const {
      technology,
      material,
      surfaceFinish,
      materialColor,
      color,
    } = itemDetails;

    loadSelectColorSurfaces({ technology });

    const metadata = itemDetails.metadata || {};
    const threeDTechnology = !is3DPTechnology(technology)
      ? null
      : metadata.threeDTechnology === THREE_D_P_OLD_SLA_VALUE_TECH
        ? THREE_D_P_SLA_TECH
        : metadata.threeDTechnology;

    const allTechOptions = await loadTechnologyOptions(false);
    if (!allTechOptions.includes(technology)) {
      setTechnology(TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY);
      setOtherTechnology(technology);
    } else {
      setTechnology(technology);
      setOtherTechnology(null);
    }
    if (technology === TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE) {
      setIsDimensionDataRequired(false);
    } else {
      setIsDimensionDataRequired(true);
    }

    let materialOptions = [];
    if (!is3DPTechnology(technology)) {
      const { options: materialCategories } = await loadMaterialCategoryOptions({
        technology,
      }, false);
      materialOptions = materialCategories.flatMap(item => item.options);
    } else {
      await load3DTechnologyOptions(false);
      const { options: threeDPMaterials } = await loadThreeDMaterialOptions({
        technology,
        threeDTechnology,
      }, false).catch(() => {
        setDisableSubmitButton(true);
      });
      materialOptions = threeDPMaterials;
    }

    if (isCustomMaterial(material) || !materialOptions.includes(material)) {
      setMaterial('Custom Material');
      setOtherMaterial(itemDetails.customMaterial || material);
    } else {
      setMaterial(material);
      setOtherMaterial(itemDetails.customMaterial);
    }

    const params = {
      technology,
      threeDTechnology,
      material,
    }
    const { options: allSurfaceOptions } = await loadSurfaceFinishOptions(params, false);

    if (isCustomSurfaceFinish(surfaceFinish) || (surfaceFinish && !(allSurfaceOptions || []).includes(surfaceFinish))) {
      if (surfaceFinish && isEmpty(allSurfaceOptions)) {
        setSurfaceFinishOptions(['Custom Finish']);
      }
      setSurfaceFinish('Custom Finish');
      setOtherSurfaceFinish(itemDetails.customSurfaceFinish || surfaceFinish);
    } else {
      setSurfaceFinish(surfaceFinish);
      setOtherSurfaceFinish(itemDetails.customSurfaceFinish);
    }

    if (materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (color) {
      loadSurfaceFinishColorOptions({
        technology,
        surfaceFinish,
      }, false);
    }
    setItemMeasurementUnit(itemDetails?.unitType)
  });

  useEffect(() => {
    getAllBuyers().then(data => {
      setAllBuyers(data);
    })
  }, []);

  useEffect(() => {
    if (loadingStatus === 'success') {
      setItem(itemDetails);
      populateItemDetails(itemDetails);
      setEditRegeneratePO(false);
    }
  }, [itemDetails]);

  const validateForm = () => {
    if (isDialog && isEmpty(editCadFile)) {
      setFormError({
        editCadFile: 'Design file is required',
      });
      cafFileUploadRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (isCustomTechnology(technology) && isEmpty(otherTechnology)) {
      setFormError({
        otherTechnology: 'Required field',
      });
      otherTechnologyRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && isEmpty(threeDTechnology)) {
      setFormError({
        threeDTechnology: 'Required field',
      });
      threeDTechFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (isCustomMaterial(material) && isEmpty(otherMaterial)) {
      setFormError({
        otherMaterial: 'Required field',
      });
      otherMaterialFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmpty(otherSurfaceFinish)) {
      setFormError({
        otherSurfaceFinish: 'Required field',
      });
      otherSurfaceFinishFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (!isEmpty(invoiceUrl) && !isURL(invoiceUrl)) {
      setFormError({
        invoiceUrl: 'Invalid invoice URL',
      });
      invoiceUrlFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (shipmentInformation?.shipmentProvider === 'Other' && !shipmentInformation?.otherShipmentProvider) {
      otherShipmentProviderRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    if (isDimensionDataFieldRequired) {
      const showDimensionDataError = (key, ref) => {
        setFormError({
          [key]: 'Required field',
        });
        ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        updatePpeInformation({ expandedAccordion: true })
        return false
      }
      if (!ppeInformation?.boundingBoxX) {
        return showDimensionDataError('boundingBoxX', sizeXRef);
      }
      if (!ppeInformation?.boundingBoxY) {
        return showDimensionDataError('boundingBoxY', sizeYRef);
      }
      if (!ppeInformation?.boundingBoxZ) {
        return showDimensionDataError('boundingBoxZ', sizeZRef);
      }
    }
    if (showImageConfirmationCheckbox && !imageOkConfirmation) {
      setFormError({
        imageOk: 'Please check this to confirm PDF Image first',
      });
      imageOkCheckboxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      notifyError(`Please confirm PDF Image first`);
      return false;
    }
    if (isURL(shipmentInformation?.shipmentTrackingNumber || '')) {
      updateShipmentInformation({ expandedAccordion: true })
      shipmentTrackingNumberRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (isEmpty(item)) {
      return;
    }

    setEditName(item.name || "");
    setEditReferenceName(
      item.referenceName || `${String(item.itemID).padStart(4, "0")}`
    );
    setEditQuantity(item.quantity || 0);
    setEditStatus(item.status || itemStatus[0].value);
    setCancellationReason(item.cancellationReason || "");
    setEditPaymentStatus(item.paymentStatus || paymentStatus[0].value);
    setRequiredRework(item.requiredRework);

    const metadata = item.metadata || {};
    if (is3DPTechnology(item.technology)) {
      const threeDTech = metadata.threeDTechnology === THREE_D_P_OLD_SLA_VALUE_TECH
        ? THREE_D_P_SLA_TECH
        : metadata.threeDTechnology;
      setThreeDTechnology(threeDTech);
      if (threeDTech === THREE_D_P_FDM_TECH) {
        setThreeDInfill(metadata.threeDInfill);
        setThreeDLayerThickness(metadata.threeDLayerThickness);
      }
    }
    setEditDeadline(item.deadline
      ? new Date(item.deadline)
      : null
    );
    setDateOfExpiry(
      item.dateOfExpiry ? new Date(item.dateOfExpiry) : null
    );
    setEditCollectionDate(
      item.collectionDate ? new Date(item.collectionDate) : null
    );
    setCollectionDate(
      item.collectionDate ? new Date(item.collectionDate) : null
    );
    setEditActualCollectionDate(
      item.actualCollectionDate ? new Date(item.actualCollectionDate) : null
    );
    setEditRevisedCollectionDate(
      item.revisedCollectionDate ? new Date(item.revisedCollectionDate) : null
    );
    setEditReworkDeliveryDate(
      item.reworkDeliveryDate ? new Date(item.reworkDeliveryDate) : null
    );
    setEditDeliveryDate(item.deliveryDate ? new Date(item.deliveryDate) : null);
    setEditActualDeliveryDate(
      item.actualDeliveryDate
        ? new Date(item.actualDeliveryDate)
        : null
    );
    setEditRevisedDeliveryDate(item.revisedDeliveryDate ? new Date(item.revisedDeliveryDate) : null);
    setEditDescription(item.description || "");
    setEditPartApplication(item.partApplication || "");
    setEditDeliveryPreference(item.deliveryPreference || "on_premise");
    setEditTolerance(item.tolerance || "");
    // if unit type is in metric, keep it as-is
    // if it's in imperial, convert it into imperial
    // because the tolerance is always stored in mm in db
    setDisplayedTolerance(item.unitType === UNIT_TYPES.METRIC ? item.tolerance : convertMetricToImperial(item.tolerance));
    setEditCadFile(item.cadFile ? item.cadFile.split(",") : []);
    setEditImageFile(item.imageFile || item.twoDImageUrl || "");
    setCurrentImg(
      item.imageFile ||
      item.twoDImageUrl ||
      "https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder"
    );

    setColor(item.color || "");
    setEditExpectedPrice(item.expectedPrice || 0.0);
    setEditTargetPriceSupplier(item.targetPriceSupplier);
    setEditActualDeliveryCost(item.actualDeliveryCost || 0.0);
    setEditDatePosted(item.datePosted || "");
    setEditUserID(item.userID || myUserId);
    setEditPrice(item.price || "");
    setEditPpeMarkdown(item.ppeMarkdown || "");
    setEditOriginalFiles(
      item.originalFiles ? item.originalFiles.split(",") : []
    );
    setVerifiedDate(
      item.verifiedDate
        ? getDateStr(item.verifiedDate)
        : ""
    );
    setQcReports(item.qcReports);
    setHubspotDealID(item.hubspotDealID || '');
    setDeliveryOption(isEmpty(item.deliveryOption)
      ? 'N.A.'
      : DELIVERY_OPTIONS_DISPLAY_MAPPING[item.deliveryOption]
    );
    setMaterialColor(item.materialColor);
    setInvoiceUrl(item.invoiceUrl);
    setItemHashcodeCad(item.itemHashcodeCad);
    setItemHashcodePdf(item.itemHashcodePdf);
    setRefPartID(item.refPartID);
    setRefQuoteID(item.refQuoteID);
    setRequestSameFabricator(item.requestSameFabricator);
    setDfmInput(item.dfmInput);
    setPpeDataPoint(item.ppeDataPoint);
    setBiDataPoint(item.biDataPoint);
    setClarifying(item.clarifying)
    setDataPointLoaded(true)

    /**
     * Only use item.ppeInformation as the main ppeInformation.
     * The last instant log is only for the legacy item.
     * @param {*} key
     * @returns
     */
    const getPpeInfoValue = (key) => {
      const ppeInfo = item?.ppeInformation ?? getPpeInfo(item)
      return get(ppeInfo, key) ?? null
    }
    updatePpeInformation({
      boundingBoxX: getPpeInfoValue("boundingBoxX"),
      boundingBoxY: getPpeInfoValue("boundingBoxY"),
      boundingBoxZ: getPpeInfoValue("boundingBoxZ"),
      volume: getPpeInfoValue("volume"),
      weight: getPpeInfoValue("weight"),
      surfaceArea: getPpeInfoValue("surfaceArea"),
    })
    updateShipmentInformation({
      shipmentProvider: !SHIPMENT_PROVIDERS.includes(item?.shipmentProvider) && item?.shipmentProvider ? 'Other' : item?.shipmentProvider,
      otherShipmentProvider: !SHIPMENT_PROVIDERS.includes(item?.shipmentProvider) && item?.shipmentProvider ? item?.shipmentProvider : null,
      shipmentTrackingNumber: item?.shipmentTrackingNumber,
      shipmentDate: item?.shipmentDate ? new Date(item?.shipmentDate) : null,
    })
    updateQualityInformation(getQualityProperties(item, null, true))
  }, [item]);

  useEffect(() => {
    const filteredBuyers = !isEmpty(filterText)
      ? allBuyers.filter(user => user.name.toLowerCase().includes(filterText.toLowerCase()))
      : allBuyers
    setBuyers(filteredBuyers);
    setTimeout(() => {
      if (selectBuyersRef.current) {
        selectBuyersRef.current.focus();
      }
    }, 200);
  }, [allBuyers, filterText]);

  // Technical Drawing Extractor
  const [
    localTechnicalDrawingExtractionState,
    updateLocalTechnicalDrawingExtractionState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      customerTechnicalDrawings: [],
      hasExtractionAttempt: false,
      expandedAccordion: false,

      selectedPdfUrlToExtract: undefined,

      name: undefined,
      createdAt: undefined,
      sourceUrl: undefined,
      output: undefined,

      isExtracting: false,
    }
  );

  useEffect(() => {
    const checkExtractedMaterialPlastic = async () => {
      if (!localTechnicalDrawingExtractionState) {
        setIsExtractedMaterialPlastic(false);
        return;
      }
      const material = localTechnicalDrawingExtractionState.output?.matched_material;
      const technology = itemDetails?.technology;
      try {
        const response = await getSurfaceFinishingOptionsForSupplier({ technology, material });
        setIsExtractedMaterialPlastic(isEmptyValue(response));
      } catch (error) {
        setIsExtractedMaterialPlastic(false);
      }
    };
    if (localTechnicalDrawingExtractionState) {
      checkExtractedMaterialPlastic();
    }
  }, [localTechnicalDrawingExtractionState]);

  const updateLocalTechnicalDrawingExtractionStateWithApiOutput = (data) => {
    let output = data.output ?? data.response
    if ("tde_response" in output) {
      output = output.tde_response
    }
    updateLocalTechnicalDrawingExtractionState({
      hasExtractionAttempt: true,
      name: data.name,
      createdAt: data.createdAt ? getDateStr(data.createdAt) : "",
      sourceUrl: data.sourceUrl ?? data.request?.pdf_url,
      output
    });
  }

  const getLatestTechnicalDrawingExtractionAttempt = async () => {
    const data = await getLatestTechnicalDrawingExtractionAttemptById(item.itemID);
    return data;
  };

  const showDimensionFieldError = (key) => {
    return isDimensionDataRequired && !get(ppeInformation, key) && get(formError, key)
  }

  useEffect(() => {
    if (!item) {
      return;
    }

    const listFiles = [
      ...new Set([
        ...(isEmptyValue(item.cadFile) ? [] : item.cadFile.split(',')),
        ...(isEmptyValue(item.originalFiles) ? [] : item.originalFiles.split(',')),
      ])
    ];

    const customerTechnicalDrawings = listFiles.filter(url => isPdfFile(url));
    if (customerTechnicalDrawings.length === 0) {
      return;
    }
    updateLocalTechnicalDrawingExtractionState({
      customerTechnicalDrawings,
      selectedPdfUrlToExtract: customerTechnicalDrawings[0],  // Use the first pdf as default
    });

    try {
      getLatestTechnicalDrawingExtractionAttempt().then(data => {
        if (data) {
          updateLocalTechnicalDrawingExtractionStateWithApiOutput(data);
        }
      });
    } catch (err) {
      notifyError(err)
    }
  }, [item])

  const handleExtractTechnicalDrawing = async () => {
    updateLocalTechnicalDrawingExtractionState({ isExtracting: true });
    try {
      const userAgentInfo = cookies.get(USER_AGENT_INFO_KEY);

      const body = {
        pdfUrl: localTechnicalDrawingExtractionState.selectedPdfUrlToExtract,
        tdeLocation: TDE_LOCATIONS.ADMIN_PLATFORM_VERIFY_OR_EDIT_ITEM,
        userAgentInfo,
        rom_switch: false,
        tech: techMapping[item.technology]
      }
      const data = await extractTechnicalDrawingByIdAndPdfUrl(item.itemID, body);
      updateLocalTechnicalDrawingExtractionStateWithApiOutput(data);
      notifySuccess('Technical drawing extracted successfully!');
    } catch (err) {
      notifyError(err.message);
    } finally {
      updateLocalTechnicalDrawingExtractionState({
        isExtracting: false,
        expandedAccordion: true,
      });
    }
  }

  const renderTechnicalDrawingExtractionField = (label, value) => {
    const allowedCheckWarningInfoIcon = [TDE_RESPONSE_KEYS.special_threads];

    const unitAdornment = () => (<InputAdornment position='start'>{UNIT_OPTIONS[UNIT_TYPES.METRIC].LENGTH}</InputAdornment>)
    const checkWarningAdornment = () => (
      <InputAdornment position='end'>
        <CheckAndWarningInfo compareValue={CHECK_TDE_RESPONSE_VALUES[label]} value={value} />
      </InputAdornment>
    )

    const endAdornment = (label) => {
      if (label.toLowerCase().includes("tolerance")) {
        return unitAdornment()
      }

      if (allowedCheckWarningInfoIcon.includes(label)) {
        return checkWarningAdornment()
      }

      return;
    }

    return (
      <TextField
        label={label}
        variant="outlined"
        disabled
        value={value}
        margin="dense"
        InputLabelProps={{ shrink: true }}
        fullWidth
        multiline
        className={classes.fullHeight}
        InputProps={{
          style: {
            borderRadius: '10px',
          },
          endAdornment: endAdornment(label)
        }}
      />
    )
  }
  /* End of Technical Drawing Extractor */

  function handleImgPartChange(e) {
    const file = e.target.files[0];
    const s3ObjectKey = getImageFileS3Key(file);
    uploadPublicFileToS3(file, s3ObjectKey)
      .then(data => {
        setEditImageFile(data.Location.split(" ").join("%20"));
        setCurrentImg(data.Location);
      });
  }

  function handleCadFilesChange(files) {
    setFormError({});
    let stringOfLinks = [...editCadFile];
    const toastId = toast('File(s) is uploading', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    let isPdf = false;
    Promise.all(
      Array.from(files).map(async file => {
        const filename = getCadPartS3Key(file);
        if (file.type === "application/pdf") {
          isPdf = true
        }
        const formData = new FormData();
        formData.append('filename', filename);
        formData.append('file', file);
        formData.append('flattenPdf', flattenPdf);
        return pdfFlatten(formData)
          .then(({ data }) => {
            stringOfLinks.push(data.split(" ").join("%20"));
            return Promise.resolve();
          })
          .catch(err => {
            toast.error(`Error uploading ${filename}: ${err.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(err);
          });
      })
    ).then(() => {
      setEditCadFile(stringOfLinks);
      if (isPdf && flattenPdf) {
        toast.success("PDF(s) flattened successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      toast.update(toastId, {
        render: 'File(s) is uploaded successfully',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    })
      .catch(() => {
        toast.update(toastId, {
          render: 'Some file(s) failed to upload',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });
  }

  function handleOriginalCadFilesChange(files) {
    let stringOfOriginalLinks = [...editOriginalFiles];
    const toastId = toast('File(s) is uploading', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    Promise.all(
      Array.from(files).map(file => {
        const filename = getCadPartS3Key(file);
        return uploadFileToS3(file, filename)
          .then(data => {
            stringOfOriginalLinks.push(data.Location.split(" ").join("%20"));
          })
          .catch(err => {
            alert(err);
          });
      })
    ).then(() => {
      setEditOriginalFiles(stringOfOriginalLinks);
      toast.update(toastId, {
        render: 'File(s) is uploaded successfully',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    });
  }

  const handleChangeAccordionPpeInfo = () => (event, isExpanded) => {
    updatePpeInformation({ expandedAccordion: isExpanded });
  };

  const handleChangeAccordionShipmentInfo = () => (event, isExpanded) => {
    updateShipmentInformation({ expandedAccordion: isExpanded });
  };

  const validateName = name => {
    if (name) {
      setNamePresent(true);
    } else {
      setNamePresent(false);
    }
  };

  const validateQty = qty => {
    if (qty) {
      setQtyPresent(true);
    } else {
      setQtyPresent(false);
    }
  };

  const getEditPart = () => {
    const editPart = {
      itemID: item.itemID,
      editName,
      editReferenceName,
      editStatus,
      cancellationReason,
      editPaymentStatus,
      requiredRework,
      editQuantity,
      editTech: technology,
      otherTechnology,
      editDeadline,
      dateOfExpiry: dateOfExpiry === null ? null : getDateStr(dateOfExpiry),
      editDescription,
      surfaceFinish,
      otherSurfaceFinish: isCustomSurfaceFinish(surfaceFinish) ? otherSurfaceFinish : null,
      editPartApplication,
      editDeliveryPreference,
      editTolerance,
      editCadFile,
      editImageFile,
      material,
      otherMaterial: isCustomMaterial(material) ? otherMaterial : null,
      materialColor,
      editExpectedPrice,
      editActualDeliveryCost,
      editDatePosted,
      editUserID,
      editPrice,
      editPpeMarkdown,
      editOriginalFiles,
      color,
      verifiedDate,
      editDeliveryDate,
      editCollectionDate,
      editActualDeliveryDate,
      editActualCollectionDate,
      editRevisedDeliveryDate,
      editRevisedCollectionDate,
      editReworkDeliveryDate: requiredRework === 0 ? null : editReworkDeliveryDate,
      hubspotDealID,
      invoiceUrl,
      itemHashcodeCad,
      itemHashcodePdf,
      refPartID,
      refQuoteID,
      requestSameFabricator,
      regeneratePO: editRegeneratePO,
      dfmInput,
      ppeDataPoint,
      biDataPoint,
      unitType: itemMeasurementUnit,
      ppeInformation: {
        boundingBoxX: ppeInformation?.boundingBoxX,
        boundingBoxY: ppeInformation?.boundingBoxY,
        boundingBoxZ: ppeInformation?.boundingBoxZ,
        surfaceArea: ppeInformation?.surfaceArea,
        volume: ppeInformation?.volume,
        weight: ppeInformation?.weight,
      },
      shipmentInformation: {
        shipmentProvider:
          shipmentInformation?.shipmentProvider === 'Other'
            ? shipmentInformation?.otherShipmentProvider
            : shipmentInformation?.shipmentProvider,
        shipmentTrackingNumber: shipmentInformation?.shipmentTrackingNumber,
        shipmentDate: shipmentInformation?.shipmentDate,
      },
      qualityInformation: getQualityProperties(qualityInformation, null, true),
      editTargetPriceSupplier,
    }
    if (is3DPTechnology(technology)) {
      editPart.threeDTechnology = threeDTechnology;
      editPart.threeDInfill = threeDInfill;
      editPart.threeDLayerThickness = threeDLayerThickness;
    }
    return editPart;
  }

  const handleConfirmSubmit = () => {
    const editPart = getEditPart();
    updateTdeDataFromState({
      editPart,
      tdeState: localTechnicalDrawingExtractionState,
      mapper: MATCHED_TDE_OUTPUT_MAPPING
    });

    setEditRefetching(true);
    submitEditPart(editPart);
    setWarningDialog(false);
    setTimeout(() => {
      editItemRefetch().finally(setEditRefetching(false));
    }, 2000);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    const valid = validateForm();
    if (!valid) {
      return;
    }
    const isRegeneratePO = collectionDate == null || isEmptyValue(editCollectionDate) == null || collectionDate?.getTime() === editCollectionDate?.getTime() ? false : true;
    setEditRegeneratePO(isRegeneratePO);
    if (isRegeneratePO) {
      setWarningDialog(true);
    } else {
      handleConfirmSubmit()
    }
  };

  const handleUpdateDimension = (key, value) => {
    if (!isEmptyValue(ppeInformation.dfm)) {
      updatePpeInformation({ dfm: { ...ppeInformation.dfm, [key]: value } });
      return
    }
    updatePpeInformation({ [key]: value })
  }

  const handleUpdatePpeDataPoint = async (event) => {
    const value = event.target.checked
    setPpeDataPoint(value)
    const body = {
      ppeDataPoint: value,
    }
    const updatedQuotes = await editItemDataPoint(editItemID, body);
    notifySuccess("Updated Successfully")

    const quoteNames = updatedQuotes.map(quote => {
      return quote.quotationID;
    });

    if (quoteNames.length > 0) {
      const successMessage = `Additionally, quotes (${quoteNames.join(', ')}) updated successfully`;
      notifySuccess(successMessage)
    }
  }

  const handleUpdateClarifyingStatus = async (event) => {
    const value = event.target.checked
    const body = {
      updatedStatus: value,
      actionType: ADMIN_UPDATE_ACTION_TYPES.CLARIFY
    }

    setEditRefetching(true)

    adminItemStatusUpdate(editItemID, body).then((response) => {
      if (response.success) {
        editItemRefetch().finally(() => {
          notifySuccess(`Clarification status updated for Item #${editItemID}`);
          setClarifying(true);
          setEditRefetching(false);
        })
      }
    }).catch((err) => {
      notifyError(err?.message || "Unexpected error!")
    });
  }
  const handleUpdateBiDataPoint = async (event) => {
    const value = event.target.checked
    setBiDataPoint(value)
    const body = {
      biDataPoint: value,
    }
    const updatedQuotes = await editItemDataPoint(editItemID, body);
    notifySuccess("Updated Successfully")

    const quoteNames = updatedQuotes.map(quote => {
      return quote.quotationID;
    });

    if (quoteNames.length > 0) {
      const successMessage = `Additionally, quotes (${quoteNames.join(', ')}) updated successfully`;
      notifySuccess(successMessage)
    }
  }

  const handleFetchDimensions = async () => {
    updatePpeInformation({ fetchLoading: true });
    updatePpeInformation({ fetchLoading: true });
    const cadFile = !isEmptyValue(editCadFile) ? editCadFile : editOriginalFiles
    const materialDefault = isCustomMaterial(material) ? otherMaterial : material;
    const params = {
      process: techMapping[technology] || technology,
      file_url: await getUrlFileBySupportedFileTypes(cadFile),
      material: getPpeMaterialCode({ technology, material }) || materialDefault,
      quantity: Number(editQuantity),
      customer_image_file_location: editImageFile,
    }
    if (is3DPTechnology(technology)) {
      params.threeDTechnology = THREE_D_P_TECH_ABBRE_MAPPING[threeDTechnology] || threeDTechnology;
    }
    getDfmExtractDimensions(params)
      .then((res) => {
        notifySuccess("Item dimensions fetched successfully!")
        updatePpeInformation({ dfm: res.data, fetchLoading: false });
      })
      .catch((err) => {
        notifyError(err?.message || "Unexpected error!")
        updatePpeInformation({ fetchLoading: false });
      })
  }

  const handleSavePpeInformation = () => {
    updatePpeInformation({ saveLoading: true })
    const params = {
      boundingBoxX: ppeInformation.dfm?.boundingBoxX ?? ppeInformation.boundingBoxX ?? null,
      boundingBoxY: ppeInformation.dfm?.boundingBoxY ?? ppeInformation.boundingBoxY ?? null,
      boundingBoxZ: ppeInformation.dfm?.boundingBoxZ ?? ppeInformation.boundingBoxZ ?? null,
      volume: ppeInformation.dfm?.volume ?? ppeInformation.volume ?? null,
      weight: ppeInformation.dfm?.weight ?? ppeInformation.weight ?? null,
      surfaceArea: ppeInformation.dfm?.surfaceArea ?? ppeInformation.surfaceArea ?? null,
    }
    updatePpeInformationItem(editItemID, params)
      .then(() => {
        updatePpeInformation({ dfm: null, saveLoading: false, ...params });
        notifySuccess('Item dimensions have been updated successfully!');
      })
      .catch((err) => {
        notifyError(err?.message || "Unexpected error!")
        updatePpeInformation({ saveLoading: false });
      });
  }

  const renderUploadNewImage = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        {showImageConfirmationCheckbox && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '1rem',
            }}
            ref={imageOkCheckboxRef}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={imageOkConfirmation}
                  onChange={(e) => {
                    setImageOkConfirmation(e.target.checked);
                    setFormError({
                      imageOk: null,
                    });
                  }}
                  name='imageOkConfirmation'
                />
              }
              label='Technical Drawing Image Updated'
            />
            {formError.imageOk && (
              <Typography
                variant="caption"
                style={{
                  color: 'red',
                  fontStyle: 'italic',
                }}
              >
                {formError.imageOk}
              </Typography>
            )}
          </div>
        )}
        {renderItemImage()}
      </div>
    );
  }

  const renderTechnologySelect = () => {
    return (
      <TechnologyInputField
        value={technology}
        onChange={(newTech) => {
          setTechnology(newTech);
          technologyHasChanged(newTech);
          if (newTech === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
            setThreeDInfill(threeDPrintingInfillDefault);
            setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
          }
          if (newTech === TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE) {
            setIsDimensionDataRequired(false);
          } else {
            setIsDimensionDataRequired(true);
          }
        }}
        technologyOptions={technologyOptions}
        bootstrapStyle={false}
      />
    );
  }

  const render3DPrintingTechnologySelect = () => {
    return (
      <div
        ref={threeDTechFieldRef}
      >
        <ThreeDPrintingTechnologyInputField
          visible={is3DPTechnology(technology)}
          value={threeDTechnology}
          onChange={(newValue) => {
            setThreeDTechnology(newValue);
            setFormError({});
            const params = {
              technology,
              threeDTechnology: newValue,
            }
            threeDTechnologyHasChanged(params);
            if (newValue === THREE_D_P_FDM_TECH) {
              setThreeDInfill(threeDPrintingInfillDefault);
              setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
            } else {
              setThreeDInfill('');
              setThreeDLayerThickness('');
            }
          }}
          error={formError.threeDTechnology}
          threeDTechnologyOptions={threeDTechnologyOptions}
          bootstrapStyle={false}
        />
      </div>
    );
  }

  const render3DInfillSelect = () => {
    return (
      <ThreeDInfillFieldSelect
        value={threeDInfill}
        onChange={evt => setThreeDInfill(evt.target.value)}
      />
    );
  }

  const render3DLayerThicknessSelect = () => {
    return (
      <ThreeDLayerThicknessFieldSelect
        onChange={evt => setThreeDLayerThickness(evt.target.value)}
        value={threeDLayerThickness}
      />
    );
  }

  const renderOtherTechnology = () => {
    return (
      <div ref={otherTechnologyRef} style={{ width: '100%' }}>
        <TextField
          label="Custom Technology"
          variant="outlined"
          required
          onChange={evt => setOtherTechnology(evt.target.value)}
          value={otherTechnology}
          error={formError.otherTechnology}
          helperText={formError.otherTechnology}
          margin="dense"
          fullWidth
        />
      </div>
    );
  }

  if (loadingStatus !== 'success' || isEmpty(item)) {
    return 'loading';
  }

  const renderQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
        }}
      >
        <Typography style={{ fontWeight: 'bold' }} variant="body">QC Reports</Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </div>
      </div>
    )
  }

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions) || color}
        value={color}
        colorInputLabel={'Color'}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => setColor(selectedColor || color)}
        bootstrapStyle={false}
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={materialColor}
        colorInputLabel={"Material Color"}
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) => setMaterialColor(selectedColor || materialColor)}
        bootstrapStyle={false}
      />
    );
  }

  const renderTechnicalDrawingExtractorDetails = () => {
    if (isEmptyValue(localTechnicalDrawingExtractionState.output)) {
      return;
    }

    return (
      <>
        {Object.keys(OUTPUT_TO_NON_MATCHED_FIELDS_MAPPING)
          .map((key) => key.toLowerCase())
          .filter(key => !!localTechnicalDrawingExtractionState.output[key])
          .map((key) => {
            let value = localTechnicalDrawingExtractionState.output[key];
            if (Array.isArray(value)) {
              value = value.join(', ');
            }
            return renderTechnicalDrawingExtractionField(
              OUTPUT_TO_NON_MATCHED_FIELDS_MAPPING[key.toUpperCase()],
              String(value)
            );
          })}
        {Object.keys(localTechnicalDrawingExtractionState.output)
          ?.filter(field => {
            return !Object.keys(OUTPUT_TO_NON_MATCHED_FIELDS_MAPPING).map(label => label.toLowerCase()).includes(field)
              && !Object.keys(OUTPUT_TO_MATCHED_FIELDS_MAPPING).map(label => label.toLowerCase()).includes(field)
              && !HIDDEN_FIELDS.map(label => label.toLowerCase()).includes(field)
          }).map(field => {
            let value = localTechnicalDrawingExtractionState.output[field];
            if (Array.isArray(value)) {
              value = value.join(', ');
            }
            return renderTechnicalDrawingExtractionField(
              field,
              String(value)
            );
          })}
      </>
    );
  }

  const renderTechnicalDrawingExtractor = () => {
    return (
      <>
        <FtrAccordion
          header={(
            <FlexRow style={{ gap: '5px' }}>
              <FtrAccordion.Header>
                Technical Drawing Extractor
              </FtrAccordion.Header>
              <FlexRow style={{ gap: 0 }}>
                {localTechnicalDrawingExtractionState.output?.repeat_pdf && (
                  <RepeatOrderIcon tooltipText='Repeat PDF' />
                )}
                {localTechnicalDrawingExtractionState.output?.special_threads && (
                  <CheckAndWarningInfo
                    key={'special-thread'}
                    compareValue={'Not found'}
                    value={localTechnicalDrawingExtractionState.output?.special_threads}
                    tooltipTextCheck={'Special Thread: Not Found'}
                    tooltipTextWarning={'Special Thread: Found'}
                    className={classes.warningCheckIcon}
                  />
                )}
              </FlexRow>
            </FlexRow>
          )}
          details={renderTechnicalDrawingExtractorDetails()}
        />
        {localTechnicalDrawingExtractionState.customerTechnicalDrawings.length !== 0 &&
          <Tooltip title="Select the customer technical drawing for the next extraction, triggered by the button below.">
            <TextField
              id="select-technical-drawing"
              select
              label="Customer Technical Drawing for Extraction"
              value={localTechnicalDrawingExtractionState.selectedPdfUrlToExtract}
              onChange={evt => updateLocalTechnicalDrawingExtractionState({
                selectedPdfUrlToExtract: evt.target.value
              })}
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
            >
              {localTechnicalDrawingExtractionState.customerTechnicalDrawings.map((url, index) => {
                const encodedFileName = url.split("/").pop();
                const fileName = getFileNameFromCadFile(encodedFileName);
                return (
                  <MenuItem key={index} value={url}>{fileName}</MenuItem>
                )
              })}
            </TextField>
          </Tooltip>
        }
        <ExtractButton
          style={{ marginTop: (localTechnicalDrawingExtractionState.expandedAccordion || localTechnicalDrawingExtractionState.customerTechnicalDrawings.length > 0) ? 0 : '1rem' }}
          disabled={localTechnicalDrawingExtractionState.customerTechnicalDrawings.length === 0 || localTechnicalDrawingExtractionState.isExtracting}
          onClick={handleExtractTechnicalDrawing}
          tooltipText={localTechnicalDrawingExtractionState.customerTechnicalDrawings.length === 0
            ? "Customer did not upload any technical drawings."
            : "Clicking this will start a new attempt to extract technical drawing, based on the above selected file."
          }
          loading={localTechnicalDrawingExtractionState.isExtracting}
          hasExtractionAttempt={localTechnicalDrawingExtractionState.hasExtractionAttempt}
          name={localTechnicalDrawingExtractionState.name}
          createdAt={localTechnicalDrawingExtractionState.createdAt}
          sourceUrl={localTechnicalDrawingExtractionState.sourceUrl}
          role={localTechnicalDrawingExtractionState.role}
          buttonText='Extract Technical Drawing'
        />
      </>
    )
  }

  const renderFileUploadInfo = (uploadInfo) => {
    if (isEmptyValue(uploadInfo)) {
      return null;
    }
    const renderInfo = (info) => {
      const style = {
        fontWeight: 'bold',
        fontSize: '0.7rem',
      };
      return <span style={style}>{info}</span>;
    };
    const uploadedBy = `Uploaded by ${uploadInfo.uploadedBy}`;
    const uploadedDate = `${dateTzSingapore(uploadInfo.uploadedAt)}`;
    return (
      <Box sx={{ minWidth: 0, flex: '1 1 auto' }}>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            wordBreak: 'break-all',
          }}
        >
          <FlexColumn>
            {renderInfo(uploadedBy)}
            {renderInfo(uploadedDate)}
          </FlexColumn>
        </div>
      </Box>
    );
  };

  return (
    <div className={classes.body}>
      <Paper
        elevation={isDialog ? 0 : 1}
        variant={isDialog ? "elevation" : "outlined"}
        style={{ padding: isDialog ? 0 : "70px" }}
      >
        <div className={classes.container}>
          <div style={{ marginRight: isDialog ? 0 : "70px" }}>
            {item?.userID && editItemID && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div>Customer ID: {item.userID}</div>
                  <div>Project ID: {item.projectID}</div>
                  <div>Item ID: {editItemID}</div>
                </div>
                {renderAdminDataControlArea()}
              </div>
            )}
            <div>
              <Divider />
              {renderFactoremUploadedFilesArea()}
              <Divider />
              <div style={{ marginTop: 10 }}>Customer&#39;s Original Files:</div>
              {role === ROLE_TYPES.SUPER_ADMIN && (
                <DragDrop
                  id="original-cad-files"
                  handleFiles={handleOriginalCadFilesChange}
                />
              )}
              <List>
                {editOriginalFiles.map((link, index) => {
                  const encodedFileName = link.split("/").pop();
                  const fileName = getFileNameFromCadFile(encodedFileName);
                  const uploadInfo = item.customerCadFileUploadInfo.find(
                    (info) => info.s3ObjectUrl === link
                  );
                  return (
                    <ListItem
                      key={index}
                      style={{
                        backgroundColor: colors.mainLightBlue
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AttachFileIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <Link
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadS3File(link);
                        }}
                      >
                        {fileName}
                      </Link>
                      <ListItemSecondaryAction>
                        <FlexRowEnd>
                          {renderFileUploadInfo(uploadInfo)}
                          <IconButton
                            edge='end'
                            onClick={() =>
                              navigator.clipboard.writeText(link)
                            }
                          >
                            <FileCopyIcon />
                          </IconButton>
                          {role === ROLE_TYPES.SUPER_ADMIN && (
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => {
                                const cadPartOriginalFiles = [
                                  ...editOriginalFiles,
                                ];
                                cadPartOriginalFiles.splice(index, 1);
                                setEditOriginalFiles(cadPartOriginalFiles);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </FlexRowEnd>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Divider />
              <div className={classes.property}>
                {role === "admin" ||
                  role === "reviewer" ||
                  role === "superadmin" ? (
                  <div>
                    <div style={{ padding: "10px 0px" }}>
                      <div>
                        *PPE price shown to suppliers will be lowered by the
                        markdown % inputted here. If this part is NOT a PPE
                        part, leave the field blank or input &#39;0&#39;.
                      </div>
                      <TextField
                        label="PPE Price (S$)"
                        variant="outlined"
                        onChange={evt => {
                          setEditPrice(evt.target.value);
                        }}
                        value={editPrice}
                        margin="dense"
                      />
                      <TextField
                        label="PPE Markdown (%)"
                        variant="outlined"
                        onChange={evt => {
                          setEditPpeMarkdown(evt.target.value);
                        }}
                        value={editPpeMarkdown}
                        margin="dense"
                        style={{ marginLeft: "20px" }}
                      />
                    </div>
                    <Divider />
                  </div>
                ) : (
                  <div />
                )}

                {renderAutomatedExtractorInfo()}

                {!isEmptyValue(requestSameFabricator) && (
                  <div>
                    <FormControlLabel
                      style={{
                        marginLeft: 0,
                        marginTop: '0.5rem',
                      }}
                      checked={requestSameFabricator}
                      onChange={(event) => setRequestSameFabricator(event.target.checked)}
                      control={<Checkbox color="primary" />}
                      label="Request Same Factory:"
                      labelPlacement="start"
                    />
                  </div>
                )}
                {FEATURE_FLAG_HUBSPOT === 'true' && (
                  <TextField
                    label="Hubspot Deal Card ID"
                    variant="outlined"
                    onChange={evt => {
                      setHubspotDealID(evt.target.value);
                    }}
                    value={hubspotDealID}
                    margin="dense"
                  />
                )}
                <WhiteOnRedTooltip
                  disableHoverListener
                  title="Name is blank"
                  arrow
                  placement="left"
                  open={!namePresent}
                >
                  <TextField
                    label="Part name"
                    variant="outlined"
                    onChange={evt => {
                      setEditName(evt.target.value);
                      validateName(evt.target.value);
                    }}
                    value={editName}
                    margin="dense"
                  />
                </WhiteOnRedTooltip>
                {role === "admin" ||
                  role === "reviewer" ||
                  role === "superadmin" ? (
                  <div>
                    <TextField
                      label="Reference name"
                      variant="outlined"
                      onChange={evt => {
                        setEditReferenceName(evt.target.value);
                      }}
                      value={editReferenceName}
                      margin="dense"
                    />
                    <TextField
                      id="standard-select-status"
                      select
                      label="Status"
                      value={editStatus}
                      onChange={evt => setEditStatus(evt.target.value)}
                      fullWidth={true}
                      variant="outlined"
                      margin="dense"
                    >
                      {itemStatus.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {editStatus === ITEM_STAGE_TYPE.CANCELLED &&
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: `repeat(${item.cancelledDate ? 2 : 1}, 1fr)`,
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <TextField
                          id="cancellation-reason"
                          label="Cancellation Reason"
                          select
                          value={cancellationReason}
                          onChange={(evt) => setCancellationReason(evt.target.value)}
                          variant="outlined"
                          margin="dense"
                          helperText={isEmpty(cancellationReason) && "Please fill in cancellation reason"}
                          error={isEmpty(cancellationReason)}
                          fullWidth={true}
                        >
                          {Object.values(ITEM_CANCELLATION_REASON).sort().map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        {item.cancelledDate && (
                          <div className={classes.cancellationInfo}>
                            <span>{getCancelledItemInfo(item, 'Cancelled by ')}</span>
                            <span>{dateTzSingapore(item.cancelledDate)}</span>
                          </div>
                        )}
                      </div>
                    }
                    {editStatus === ITEM_STAGE_TYPE.CANCELLED && !isEmptyValue(item.customerCancellationRemarks) && (
                      <TextField
                        label="Customer Cancellation Remarks"
                        multiline
                        minRows={3}
                        variant="outlined"
                        value={item.customerCancellationRemarks}
                        margin="dense"
                        fullWidth
                        disabled
                      />
                    )}
                  </div>
                ) : (
                  <div />
                )}
                {!isDialog &&
                  (role === "admin" ||
                    role === "reviewer" ||
                    role === "superadmin") ? (
                  <TextField
                    id="standard-select-paymentStatus"
                    select
                    label="Payment Status"
                    value={editPaymentStatus}
                    onChange={evt => setEditPaymentStatus(evt.target.value)}
                    fullWidth={true}
                    variant="outlined"
                    margin="dense"
                  >
                    {paymentStatus.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <div />
                )}
                <Box style={{ display: 'flex', gap: "2px" }}>
                  <WhiteOnRedTooltip
                    disableHoverListener
                    title="Quantity is blank"
                    arrow
                    placement="left"
                    open={!qtyPresent}
                  >
                    <TextField
                      label="Quantity"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      onChange={evt => {
                        setEditQuantity(evt.target.value);
                        validateQty(evt.target.value);
                      }}
                      value={editQuantity}
                      margin="dense"
                      type="number"
                      fullWidth
                    />
                  </WhiteOnRedTooltip>
                  {localTechnicalDrawingExtractionState.output?.quantity &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING["QUANTITY"],
                      localTechnicalDrawingExtractionState.output?.quantity,
                    )
                  }
                  {(localTechnicalDrawingExtractionState.output?.quantity) && (
                    <FlexRow>
                      <CheckAndWarningInfo
                        compareValue={String(localTechnicalDrawingExtractionState.output?.quantity)}
                        value={String(editQuantity)}
                      />
                    </FlexRow>
                  )}
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Customer Target Unit Price (S$)"
                      variant="outlined"
                      onChange={evt => {
                        setEditExpectedPrice(evt.target.value)
                        setEditTargetPriceSupplier(getMarginalizedPrice(evt.target.value, item.country))
                      }}
                      value={editExpectedPrice}
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Supplier Target Unit Price (S$)"
                      variant="outlined"
                      onChange={evt => setEditTargetPriceSupplier(evt.target.value)}
                      value={editTargetPriceSupplier}
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Delivery Cost (S$)"
                  variant="outlined"
                  onChange={evt => setEditActualDeliveryCost(evt.target.value)}
                  value={editActualDeliveryCost}
                  margin="dense"
                />
                {renderTechnologySelect()}
                {isCustomTechnology(technology) && renderOtherTechnology()}
                {is3DPTechnology(technology) && render3DPrintingTechnologySelect()}
                <Box style={{ display: 'flex', gap: "2px" }}>
                  <MaterialCategoriesInputField
                    visible={!is3DPTechnology(technology)}
                    value={material}
                    onSelect={(value) => {
                      setMaterial(value);
                      const params = {
                        technology,
                        threeDTechnology,
                        material: value,
                      }
                      materialHasChanged(params);
                    }}
                    materialCategoryOptions={materialCategoryOptions}
                    bootstrapStyle={false}
                    overrideStyle={{ width: '100%' }}
                  />
                  <ThreeDPrintingMaterialField
                    visible={is3DPTechnology(technology)}
                    value={material}
                    onSelect={(_material) => {
                      setMaterial(_material);
                      const params = {
                        technology,
                        threeDTechnology,
                        material: _material,
                      }
                      materialHasChanged(params);
                    }}
                    threeDMaterialOptions={threeDMaterialOptions}
                    defaultThreeDMaterial={defaultThreeDMaterial}
                    bootstrapStyle={false}
                  />
                  {isCustomMaterial(material) && (
                    <div ref={otherMaterialFieldRef} style={{ width: '100%' }}>
                      <OtherMaterialField
                        value={otherMaterial}
                        error={formError.otherMaterial}
                        onChange={evt => setOtherMaterial(evt.target.value)}
                        onFocus={() => setFormError({})}
                      />
                    </div>
                  )}
                  {localTechnicalDrawingExtractionState.output?.material &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING["MATERIAL"],
                      localTechnicalDrawingExtractionState.output?.material
                    )
                  }
                  {localTechnicalDrawingExtractionState.output?.matched_material &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING.MATCHED_MATERIAL,
                      join(localTechnicalDrawingExtractionState.output?.matched_material),
                    )
                  }
                  {isExtractedMaterialPlastic &&
                    (localTechnicalDrawingExtractionState.output?.finish_color ||
                      localTechnicalDrawingExtractionState.output?.color) &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING.COLOR,
                      localTechnicalDrawingExtractionState.output?.finish_color || localTechnicalDrawingExtractionState.output?.color,
                    )
                  }
                  {localTechnicalDrawingExtractionState.output?.matched_material && (
                    <FlexRow>
                      <CheckAndWarningInfo
                        compareValue={localTechnicalDrawingExtractionState.output?.matched_material}
                        value={
                          isCustomMaterial(material)
                            ? otherMaterial?.trim()
                            : material?.trim()
                        }
                      />
                    </FlexRow>
                  )}
                </Box>
                {!isEmpty(materialColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderMaterialColorInputField()}
                  </div>
                )}
                {is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH && render3DInfillSelect()}
                {is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH && render3DLayerThicknessSelect()}
                <Box style={{ display: 'flex', gap: "2px" }}>
                  <SurfaceFinishingField
                    visible={!isEmpty(surfaceFinishOptions) || isCustomSurfaceFinish(surfaceFinish)}
                    value={surfaceFinish}
                    onSelect={(newValue) => {
                      setSurfaceFinish(newValue);
                      const params = {
                        technology,
                        material,
                        surfaceFinish: newValue,
                      }
                      surfaceFinishHasChanged(params);
                    }}
                    surfaceFinishOptions={surfaceFinishOptions}
                    selectColorSurfaces={selectColorSurfaces}
                    bootstrapStyle={false}
                    overrideStyle={{ width: '100%' }}
                    className={classes.fullHeight}
                  />
                  {!isExtractedMaterialPlastic &&
                    (localTechnicalDrawingExtractionState.output?.finish_color ||
                      localTechnicalDrawingExtractionState.output?.color) &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING.COLOR,
                      localTechnicalDrawingExtractionState.output?.finish_color || localTechnicalDrawingExtractionState.output?.color,
                    )
                  }
                  {localTechnicalDrawingExtractionState.output?.finish &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING["FINISH"],
                      localTechnicalDrawingExtractionState.output?.finish,
                    )
                  }
                  {localTechnicalDrawingExtractionState.output?.matched_finish &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING.MATCHED_FINISH,
                      join(localTechnicalDrawingExtractionState.output?.matched_finish),
                    )
                  }
                  {localTechnicalDrawingExtractionState.output?.matched_finish && (
                    <FlexRow>
                      <CheckAndWarningInfo
                        compareValue={localTechnicalDrawingExtractionState.output?.matched_finish?.trim()}
                        value={
                          isCustomSurfaceFinish(surfaceFinish)
                            ? otherSurfaceFinish?.trim()
                            : surfaceFinish?.trim()
                        }
                      />
                    </FlexRow>
                  )}
                </Box>
                {isCustomSurfaceFinish(surfaceFinish) && (
                  <div ref={otherSurfaceFinishFieldRef}>
                    <OtherSurfaceFinishingField
                      value={otherSurfaceFinish}
                      error={formError.otherSurfaceFinish}
                      onChange={evt => setOtherSurfaceFinish(evt.target.value)}
                      onFocus={() => setFormError({})}
                    />
                  </div>
                )}
                {(!isEmpty(surfaceFinishColorOptions) || color) && (
                  <div className={classes.sectionFormField}>
                    {renderSurfaceFinishColor()}
                  </div>
                )}
                <TextField
                  label="Description"
                  multiline
                  rows={4}
                  rowsMax={4}
                  variant="outlined"
                  onChange={evt => setEditDescription(evt.target.value)}
                  value={editDescription}
                  margin="dense"
                />
                <TextField
                  label="Part Application"
                  variant="outlined"
                  onChange={evt => setEditPartApplication(evt.target.value)}
                  value={editPartApplication}
                  margin="dense"
                />
                <TextField
                  label="Delivery Preference"
                  variant="outlined"
                  onChange={evt =>
                    setEditDeliveryPreference(evt.target.value)
                  }
                  value={editDeliveryPreference}
                  margin="dense"
                />
                <Box style={{ display: 'flex', gap: "2px" }}>
                  <TextField
                    labelId='unit-type'
                    select
                    label="Item Measurement Unit"
                    value={itemMeasurementUnit}
                    variant='outlined'
                    margin='dense'
                    onChange={(evt) => {
                      setItemMeasurementUnit(evt.target.value);
                      setDisplayedTolerance(evt.target.value === UNIT_TYPES.METRIC ? convertImperialToMetric(displayedTolerance) : convertMetricToImperial(displayedTolerance));
                    }}
                    fullWidth
                  >
                    {Object.values(UNIT_TYPES).map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {DISPLAY_UNIT_DROPDOWN[unit]}
                      </MenuItem>
                    ))}
                  </TextField>
                  {localTechnicalDrawingExtractionState.output?.measurement_unit &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING["MEASUREMENT_UNIT"], localTechnicalDrawingExtractionState.output?.measurement_unit)
                  }
                  {localTechnicalDrawingExtractionState.output?.matched_unit_type &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING.MATCHED_MEASUREMENT_UNIT, localTechnicalDrawingExtractionState.output?.matched_unit_type)
                  }
                  {(localTechnicalDrawingExtractionState.output?.matched_unit_type) && (
                    <FlexRow>
                      <CheckAndWarningInfo
                        compareValue={localTechnicalDrawingExtractionState.output?.matched_unit_type.toLowerCase()}
                        value={itemMeasurementUnit.toLowerCase()}
                      />
                    </FlexRow>
                  )}
                </Box>
                <FlexRow>
                  <TextField
                    label={'Tightest Tolerance Required'}
                    variant="outlined"
                    onChange={evt => {
                      // if unit is in metric, we take the value shown in the form
                      // if it's in imperial, we recalculate it to metric
                      setDisplayedTolerance(evt.target.value)
                      setEditTolerance(itemMeasurementUnit === UNIT_TYPES.METRIC ? evt.target.value : convertImperialToMetric(evt.target.value));
                    }}
                    value={displayedTolerance}
                    margin="dense"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                      },
                      endAdornment: (
                        <InputAdornment position='start'>{UNIT_OPTIONS[itemMeasurementUnit].LENGTH}</InputAdornment>
                      )
                    }}
                  />
                  {localTechnicalDrawingExtractionState.output?.min_tolerance &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING["MIN_TOLERANCE"],
                      localTechnicalDrawingExtractionState.output?.min_tolerance,
                    )
                  }
                  {localTechnicalDrawingExtractionState.output?.matched_metric_tolerance &&
                    renderTechnicalDrawingExtractionField(
                      OUTPUT_TO_MATCHED_FIELDS_MAPPING.MATCHED_METRIC_TOLERANCE,
                      localTechnicalDrawingExtractionState.output?.matched_metric_tolerance,
                    )
                  }
                  {(localTechnicalDrawingExtractionState.output?.min_tolerance) && (
                    <FlexRow>
                      <CheckAndWarningInfo
                        compareValue={String(localTechnicalDrawingExtractionState.output?.matched_metric_tolerance)}
                        value={String(itemMeasurementUnit === UNIT_TYPES.METRIC ? displayedTolerance : convertImperialToMetric(displayedTolerance))}
                      />
                    </FlexRow>
                  )}

                </FlexRow>

                {!isEmpty(qcReports) && renderQcReports()}
                <Fragment>
                  <DatePicker
                    label="Target Delivery Date"
                    value={editDeadline}
                    onChange={setEditDeadline}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                    emptyLabel="No Preference"
                  />
                </Fragment>
                <Fragment>
                  <DatePicker
                    label="Expiry Date"
                    value={dateOfExpiry}
                    onChange={setDateOfExpiry}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                    shouldDisableDate={(day) => {
                      return !dayjs(day).isBusinessDay();
                    }}
                  />
                </Fragment>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                  <DatePicker
                    label="Collection Date"
                    value={editCollectionDate}
                    onChange={setEditCollectionDate}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                  />
                  <DatePicker
                    label="Revised Collection Date"
                    value={editRevisedCollectionDate}
                    onChange={setEditRevisedCollectionDate}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                  />
                </div>
                <Fragment>
                  <DatePicker
                    label="Actual Collection Date"
                    value={editActualCollectionDate}
                    onChange={setEditActualCollectionDate}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                  />
                </Fragment>
                <Fragment>
                  <TextField
                    label="Delivery Option"
                    variant="outlined"
                    value={deliveryOption}
                    margin="dense"
                    disabled
                    clearable
                    clearLabel="No Preference"
                  />
                </Fragment>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                  <DatePicker
                    label="Delivery Date"
                    value={editDeliveryDate}
                    onChange={setEditDeliveryDate}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                  />
                  <DatePicker
                    label="Revised Delivery Date"
                    value={editRevisedDeliveryDate}
                    onChange={setEditRevisedDeliveryDate}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                  />
                </div>
                <Fragment>
                  <DatePicker
                    label="Actual Delivery Date"
                    value={editActualDeliveryDate}
                    onChange={setEditActualDeliveryDate}
                    animateYearScrolling
                    inputVariant="outlined"
                    margin="dense"
                    clearable
                    clearLabel="No Preference"
                  />
                </Fragment>
                {renderAssignPartToDropdown()}
                <TextField
                  ref={invoiceUrlFieldRef}
                  variant="outlined"
                  margin="dense"
                  label="Invoice URL"
                  value={invoiceUrl}
                  onChange={evt => setInvoiceUrl(evt.target.value)}
                  error={!!formError.invoiceUrl}
                  helperText={formError.invoiceUrl}
                  disabled={!shouldShowInvoice(item)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="DFM Input"
                  value={dfmInput}
                  onChange={evt => setDfmInput(evt.target.value)}
                />
                <Divider
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }} />
                <Accordion
                  key={'ship-item-information'}
                  expanded={shipmentInformation.expandedAccordion}
                  onChange={handleChangeAccordionShipmentInfo()}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                      content: classes.accordionSummaryContent,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginBottom: '0.5rem',
                      }}
                    >
                      Shipment Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TextField
                      label='Shipment Provider'
                      labelId='shipment-provider'
                      id='shipment-provider'
                      variant='outlined'
                      onChange={(evt) =>
                        updateShipmentInformation({
                          shipmentProvider: evt.target.value,
                          errors: {},
                        })
                      }
                      value={shipmentInformation?.shipmentProvider}
                      margin='dense'
                      fullWidth
                      select
                      InputLabelProps={{
                        shrink: !!shipmentInformation?.shipmentProvider,
                      }}
                    >
                      {SHIPMENT_PROVIDERS.map((shipment) => {
                        return (
                          <MenuItem key={shipment} value={shipment}>
                            {shipment}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {shipmentInformation?.shipmentProvider === 'Other' && (
                      <TextField
                        id='other-shipment-provider'
                        label='Other Shipment Provider'
                        variant='outlined'
                        fullWidth
                        size='small'
                        value={shipmentInformation?.otherShipmentProvider ?? ''}
                        margin='dense'
                        onChange={(e) =>
                          updateShipmentInformation({
                            otherShipmentProvider: e.target.value
                          })
                        }
                        ref={otherShipmentProviderRef}
                        error={!shipmentInformation?.otherShipmentProvider}
                        helperText={!shipmentInformation?.otherShipmentProvider && "Please fill in Other Shipment Provider"}
                      />
                    )}
                    <TextField
                      variant='outlined'
                      margin='dense'
                      label='Shipment Tracking Number'
                      value={shipmentInformation?.shipmentTrackingNumber}
                      onChange={(evt) => updateShipmentInformation({ shipmentTrackingNumber: evt.target.value })}
                      InputLabelProps={{
                        shrink: !!shipmentInformation?.shipmentTrackingNumber,
                      }}
                      ref={shipmentTrackingNumberRef}
                      error={isURL(shipmentInformation?.shipmentTrackingNumber || '')}
                      helperText={isURL(shipmentInformation?.shipmentTrackingNumber || '') && "Shipment Tracking Number is not an URL"}
                      InputProps={{
                        endAdornment: (() => {
                          if (![null, undefined, "N.A.", ''].includes(shipmentInformation?.shipmentTrackingNumber) &&
                            SHIPMENT_TRACKING_LINKS[shipmentInformation?.shipmentProvider]) {
                            return (
                              <InputAdornment position="end">
                                <Tooltip title={"Track shipment"}>
                                  <IconButton
                                    aria-label="open shipment tracking link"
                                    onClick={(e) => handleOpenTrackingLink(e, shipmentInformation?.shipmentProvider, shipmentInformation?.shipmentTrackingNumber)}
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            );
                          } else if (![null, undefined, "N.A.", ''].includes(shipmentInformation?.shipmentTrackingNumber) && !SHIPMENT_TRACKING_LINKS[shipmentInformation?.shipmentProvider]) {
                            return (
                              <InputAdornment position="end">
                                <Tooltip title={"Copy shipment tracking number"}>
                                  <IconButton
                                    aria-label="copy shipment tracking number"
                                    onClick={(e) => handleCopyText(e, shipmentInformation?.shipmentTrackingNumber)}
                                  >
                                    <FileCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            );
                          } else {
                            return null; // Render nothing if neither condition is met
                          }
                        })(),
                      }}
                    />
                    <DatePicker
                      label='Shipment Date'
                      value={shipmentInformation?.shipmentDate}
                      onChange={(date) => {
                        updateShipmentInformation({ shipmentDate: date })
                      }}
                      animateYearScrolling
                      inputVariant="outlined"
                      margin="dense"
                      fullWidth
                      clearable
                      clearLabel="No Preference"
                    />
                  </AccordionDetails>
                </Accordion>

                <Divider style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }} />

                {renderQualityMetricsInformation()}

                <Divider style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }} />

                {renderPPEServiceInformation()}

                <Divider style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }} />

                <Grid item style={{ display: "flex", justifyContent: 'space-between', margin: "20px 0" }}>
                  {renderUploadNewImage()}
                </Grid>
                {renderConfirmChangesButton()}
              </div>
              {renderDeletePartButton()}
            </div>
          </div>
        </div>
        <YesNoPopup
          title='Are you sure that you want to delete this part?'
          open={openDeletePartPopup}
          handleNo={() => setOpenDeletePartPopup(false)}
          handleYes={() => {
            submitDeletePart(editItemID)
            setOpenDeletePartPopup(false)
          }}
          showCloseButton={false}
        />
        <Dialog
          open={warningDialog}
          disableEscapeKeyDown
          aria-labelledby="warning-dialog"
        >
          <DialogTitle id="warning-dialog-title">
            PO will be regenerated
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="warning-dialog-content">
              {`Item has an accepted quotation and you have edited an item property that affects PO. PO will be regenerated.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setWarningDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmSubmit} color="primary">
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <LoadingBackDropText open={editRefetching} text="Reloading item data" />
    </div>
  );

  function renderItemImage() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <ImageWith3DViewer
            twoDImageUrl={currentImg}
            cadFile={item.cadFile || item.originalFiles}
            width={250}
            height={250}
            key={currentImg}
            itemID={editItemID}
            isGenerateItemImage
            borderRadius={0}
          />
          {renderUploadNewImageButton()}
        </div>
      </div>
    );
  }

  function renderUploadNewImageButton() {
    if (role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }

    return (
      <div
        style={{
          marginTop: "10px",
          marginLeft: "10px",
          display: "flex",
          alignItems: "flex-end"
        }}
      >
        <input
          accept="image/*"
          className={classes.input}
          id="img-part-file"
          type="file"
          onChange={handleImgPartChange}
          onClick={(event) => event.target.value = null}
          style={{
            display: "none"
          }} />
        <label htmlFor="img-part-file">
          <Button
            variant="outlined"
            component="span"
            color="primary"
            className={classes.button}
          >
            Upload new image
          </Button>
        </label>
      </div>
    );
  }

  function renderAdminDataControlArea() {
    if (role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }

    return dataPointLoaded && (
      <div>
        <div className={classes.row}>
          <Typography>
            PPE Data Point
          </Typography>
          <IOSSwitch
            checked={ppeDataPoint}
            onChange={handleUpdatePpeDataPoint}
            name="ppe-data-point"
            inputProps={{ 'aria-label': 'PPE Data Point' }}
            disabled={role !== ROLE_TYPES.SUPER_ADMIN} />
        </div>
        <div className={classes.row}>
          <Typography>
            BI Data Point
          </Typography>
          <IOSSwitch
            checked={biDataPoint}
            onChange={handleUpdateBiDataPoint}
            name="bi-data-point"
            inputProps={{ 'aria-label': 'BI Data Point' }}
            className={classes.switchBlue}
            disabled={!isAdminOrHigherRole(role)} />
        </div>
        <div className={classes.row}>
          <Typography>
            Customer Action Required
          </Typography>
          <IOSSwitch
            checked={clarifying}
            onChange={handleUpdateClarifyingStatus}
            name="clarifying"
            inputProps={{ 'aria-label': 'Customer Action Required' }}
            className={classes.switchRed}
            disabled={!isAdminOrHigherRole(role) || clarifying} />
        </div>
        {clarifying && !editRefetching ? (
          <FlexColumn style={{ gap: 0, marginBottom: '1rem' }}>
            <FlexRow>
              <FtrNormalText>Requested at</FtrNormalText>
              <FtrBoldText>{dateTzSingapore(item.clarifyingAt)}</FtrBoldText>
            </FlexRow>
            <FtrBoldText>By {item.clarifier}</FtrBoldText>
          </FlexColumn>
        ) : null}
      </div>
    );
  }

  function renderAutomatedExtractorInfo() {
    return [ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.ADMIN, ROLE_TYPES.REVIEWER].includes(role) && (
      <>
        <div style={{ padding: "10px 0px", marginBottom: "" }}>
          <Typography
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginBottom: '0.5rem',
            }}
          >
            Automated Extractors
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <DfmDefectsExtractionAccordion
                itemID={editItemID}
                originalFiles={editOriginalFiles}
                cadFile={editCadFile}
                technology={technology} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {renderTechnicalDrawingExtractor()}
            </Grid>
          </Grid>
        </div>
        <Divider style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
      </>
    );
  }

  function renderAssignPartToDropdown() {
    return (role === "admin" ||
      role === "reviewer" ||
      role === "superadmin") ? (
      <div style={{ marginTop: '3px' }}>
        <Typography>Assign Part To</Typography>
        <Select
          variant="outlined"
          select
          margin="dense"
          labelId='assign-part-to'
          id='assign-part-to'
          value={editUserID}
          renderValue={(selectedKey) => {
            if (!selectedKey) {
              return null;
            }
            const selectedOption = buyers.find(
              (option) => option.userID === selectedKey
            );
            if (selectedOption) {
              return `${selectedOption.name} <${selectedOption.email}>`;
            }
          }}
          InputLabelProps={{
            shrink: !!editUserID,
          }}
          fullWidth
        >
          <SearchInputDropdown
            id='select-buyer'
            key='select-buyer'
            inputRef={selectBuyersRef}
            setFilterText={setFilterText}
            filterText={filterText} />
          {buyers.map(buyer => (
            <div key={buyer.userID}>
              <MenuItem
                value={buyer.userID}
                onClick={() => setEditUserID(buyer.userID)}
              >
                {`${buyer.name} <${buyer.email}>`}
              </MenuItem>
            </div>
          ))}
        </Select>
      </div>
    ) : (
      <div />
    );
  }

  function renderQualityMetricsInformation() {
    return (
      <Accordion
        key='quality-item-information'
        defaultExpanded={false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.accordionSummaryContent,
          }}
        >
          <Typography
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginBottom: '0.5rem',
            }}
          >
            Quality
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', gap: '1rem' }}>
            <TextField
              id="required-rework"
              label="Required Rework"
              select
              value={requiredRework}
              onChange={(evt) => setRequiredRework(evt.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth={true}
            >
              <MenuItem value={1}>
                Yes
              </MenuItem>
              <MenuItem value={0}>
                No
              </MenuItem>
            </TextField>
            {requiredRework === 1 &&
              <Fragment>
                <DatePicker
                  inputRef={reworkDeliveryDateFieldRef}
                  label="Rework Delivery Date"
                  value={editReworkDeliveryDate}
                  onChange={setEditReworkDeliveryDate}
                  animateYearScrolling
                  inputVariant="outlined"
                  margin="dense"
                  clearable
                  clearLabel="No Preference"
                  fullWidth={true}
                  className={classes.reworkDeliveryDate}
                  error={!isDate(editReworkDeliveryDate)} />
              </Fragment>}
          </div>
          <QualityChecks
            style={{ marginTop: '5px' }}
            grouped
            switchState={getQualityProperties(qualityInformation, null, true)}
            onSwitchChange={(name, checked) => updateQualityInformation(getQualityPropertiesToUpdate(name, checked))} />
        </AccordionDetails>
      </Accordion>
    );
  }

  function renderPPEServiceInformation() {
    if (role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }

    return (
      <Accordion
        key={"ppe-service-information"}
        expanded={ppeInformation.expandedAccordion}
        onChange={handleChangeAccordionPpeInfo()}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.accordionSummaryContent,
          }}
        >
          <Typography
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginBottom: '0.5rem',
            }}
          >
            PPE Service Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            variant="outlined"
            margin="dense"
            label="CAD Hash Code"
            value={itemHashcodeCad}
            onChange={evt => setItemHashcodeCad(evt.target.value)} />
          <TextField
            variant="outlined"
            margin="dense"
            label="PDF Hash Code"
            value={itemHashcodePdf}
            onChange={evt => setItemHashcodePdf(evt.target.value)} />
          <TextField
            variant="outlined"
            margin="dense"
            label="Reference Part ID"
            value={refPartID}
            onChange={evt => setRefPartID(evt.target.value)} />
          <TextField
            variant="outlined"
            margin="dense"
            label="Reference Quote ID"
            value={refQuoteID}
            onChange={evt => setRefQuoteID(evt.target.value)} />
          <div
            style={{
              margin: '6px 0',
            }}
          >
            <Typography>
              PPE Hashcode Update Status: {getPpeUpdateHashcodeStatusText(item)}
            </Typography>
          </div>
          <div style={{ marginTop: '0.6rem' }}>
            <OutlinedDiv label="Instant Quote Log">
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: '0.6rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required={isDimensionDataFieldRequired}
                    style={{ flexGrow: '1' }}
                    label="Size X"
                    type="number"
                    value={ppeInformation.dfm?.boundingBoxX ?? ppeInformation.boundingBoxX ?? ''}
                    onChange={evt => handleUpdateDimension('boundingBoxX', evt.target.valueAsNumber)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>mm</InputAdornment>
                      )
                    }}
                    ref={sizeXRef}
                    error={showDimensionFieldError("boundingBoxX")}
                    helperText={showDimensionFieldError("boundingBoxX")}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required={isDimensionDataFieldRequired}
                    style={{ flexGrow: '1' }}
                    label="Size Y"
                    type="number"
                    value={ppeInformation.dfm?.boundingBoxY ?? ppeInformation.boundingBoxY ?? ''}
                    onChange={evt => handleUpdateDimension('boundingBoxY', evt.target.valueAsNumber)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>mm</InputAdornment>
                      )
                    }}
                    ref={sizeYRef}
                    error={showDimensionFieldError("boundingBoxY")}
                    helperText={showDimensionFieldError("boundingBoxY")}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required={isDimensionDataFieldRequired}
                    style={{ flexGrow: '1' }}
                    label="Size Z"
                    type="number"
                    value={ppeInformation.dfm?.boundingBoxZ ?? ppeInformation.boundingBoxZ ?? ''}
                    onChange={evt => handleUpdateDimension('boundingBoxZ', evt.target.valueAsNumber)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>mm</InputAdornment>
                      )
                    }}
                    ref={sizeZRef}
                    error={showDimensionFieldError("boundingBoxZ")}
                    helperText={showDimensionFieldError("boundingBoxZ")}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    style={{ flexGrow: '1' }}
                    label="Volume"
                    type="number"
                    value={ppeInformation.dfm?.volume ?? ppeInformation.volume ?? ''}
                    onChange={evt => handleUpdateDimension('volume', evt.target.valueAsNumber)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>cm3</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    style={{ flexGrow: '1' }}
                    label="Surface Area"
                    type="number"
                    value={ppeInformation.dfm?.surfaceArea ?? ppeInformation.surfaceArea ?? ''}
                    onChange={evt => handleUpdateDimension('surfaceArea', evt.target.valueAsNumber)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>cm2</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    label="Weight"
                    style={{ flexGrow: '1' }}
                    type="number"
                    value={ppeInformation.dfm?.weight ?? ppeInformation.weight ?? ''}
                    onChange={evt => handleUpdateDimension('weight', evt.target.valueAsNumber)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>kgs</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div>
              </div>
              <div style={{ marginTop: '0.5rem', display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Tooltip
                  title="Regenerate dimensions from the latest CAD file"
                  arrow
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: colors.purple,
                      height: '2.25rem',
                      color: colors.fontWhite
                    }}
                    onClick={handleFetchDimensions}
                    disabled={ppeInformation.fetchLoading}
                  >
                    {ppeInformation.fetchLoading && <CircularProgress size={24} color="inherit" style={{ marginRight: '0.5rem' }} />}
                    Fetch Dimensions
                  </Button>
                </Tooltip>
                {ppeInformation.dfm && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: colors.green050,
                        height: '2.25rem',
                        color: colors.fontWhite
                      }}
                      onClick={handleSavePpeInformation}
                      disabled={ppeInformation.saveLoading}
                    >
                      {ppeInformation.saveLoading && <CircularProgress size={24} color="inherit" style={{ marginRight: '0.5rem' }} />}
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: colors.red030,
                        height: '2.25rem'
                      }}
                      onClick={() => updatePpeInformation({ dfm: null })}
                    >
                      Cancel
                    </Button>
                  </>
                )}
                {isDialog && (
                  <FormControlLabel
                    control={<Checkbox
                      checked={!isDimensionDataRequired}
                      onChange={() => setIsDimensionDataRequired(!isDimensionDataRequired)}
                      name='checkbox-dimension-data-required'
                      data-cy='checkbox-dimension-data-not-required' />}
                    label='Dimension Data Not Required' />
                )}
              </div>
            </OutlinedDiv>
          </div>
        </AccordionDetails>
        <Divider />
      </Accordion>
    );
  }

  function renderDeletePartButton() {
    if (isDialog ||
      !(role === "admin" ||
        role === "reviewer" ||
        role === "superadmin")
    ) {
      return;
    }

    return (
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button
          startIcon={<DeleteIcon />}
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => setOpenDeletePartPopup(true)}
        >
          Delete Part
        </Button>
      </div>
    );
  }

  function renderConfirmChangesButton() {
    return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          fullWidth
          onClick={e => handleSubmit(e)}
          style={{ marginTop: "20px" }}
          disabled={disableSubmitButton}
        >
          Confirm changes for part
        </Button>
      );
  }

  function renderFactoremUploadedFilesArea() {
    if (role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }

    return (
      <>
        <FlexRow style={{ marginTop: 15 }}>
          <div ref={cafFileUploadRef}>Factorem uploaded files:</div>
        </FlexRow>
        <DragDrop
          id="factorem-cad-files"
          handleFiles={handleCadFilesChange}
        />
        <FlexRow>
          <Typography>
            Flatten PDF
          </Typography>
          <IOSSwitch
            checked={flattenPdf}
            onChange={() => setFlattenPdf(!flattenPdf)}
            name="flatten-pdf"
            inputProps={{ 'aria-label': 'Flatten PDF' }}
            disabled={!isAdminOrHigherRole(role)}
          />
        </FlexRow>
        {formError.editCadFile && (
          <Typography
            style={{
              color: 'red',
              fontStyle: 'italic',
            }}
            variant="caption"
          >
            {formError.editCadFile}
          </Typography>
        )}
        <List>
          {editCadFile.map((link, index) => {
            const encodedFileName = link.split('/').pop();
            const fileName = getFileNameFromCadFile(encodedFileName);
            const uploadInfo = item.factoremCadFileUploadInfo.find(
              (info) => info.s3ObjectUrl === link
            );
            return (
              <ListItem
                key={index}
                style={{
                  backgroundColor: colors.bgLightGrey
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AttachFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <Tooltip
                  title={fileName}
                >
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadS3File(link);
                    }}
                  >
                    {fileName}
                  </Link>
                </Tooltip>
                <ListItemSecondaryAction>
                  <FlexRowEnd>
                    {renderFileUploadInfo(uploadInfo)}
                    <IconButton
                      edge='end'
                      onClick={() =>
                        navigator.clipboard.writeText(link)
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        const cadPartFiles = [...editCadFile];
                        cadPartFiles.splice(index, 1);
                        setEditCadFile(cadPartFiles);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </FlexRowEnd>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    item: state.item.item,
    role: state.auth.user.role,
    myUserId: state.auth.user.userID,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    submitEditPart: editedPart => dispatch(adminEditItem(editedPart)),
    submitDeletePart: deletedPart => dispatch(deletePart(deletedPart, props))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(EditItem);
