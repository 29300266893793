const { Decimal } = require("decimal.js");
import { isEmpty, set } from "lodash";

import {
  ADD_QUOTE_FIELDS,
  FORM_ERROR,
  INIT_FORM_STATE,
  REMOVE_QUOTATION,
  TOGGLE_IGNORE_ITEM,
  UPDATE_CURRENCY,
  UPDATE_QUOTE_FIELD_VALUE,
  UPDATE_REMARKS_VALUE,
  UPDATE_WATCHING_JOB,
  UPDATE_ALL_WATCHING_JOB
} from "../actions/projectBulkQuotationsForm";
import { CURRENCY_CODE } from "../constants/currencyConstants";

import { ITEM_QUOTE_TYPE, PROJECT_TYPE, QC_FORMAL_CMM_REPORT } from "../constants/projectConstants";
import { MATERIAL_STR, OTHER_MATERIAL_STR, OTHER_SURFACE_FINISH_STR, QUANTITY_STR, REMARKS_STR, SURFACE_FINISH_STR, UNIT_QUOTE_STR } from "../constants/quotationConstants";
import formulas from '../formulas/formulas';
import { convertPriceToCurrency, convertPriceWithQuantityToCurrency } from "../utils/currencyUtils";
import { isCustomMaterial, isCustomSurfaceFinish } from "../utils/inputUtils";
import { getQuotationExchangeRate } from "../utils/quotationUtils";

const defaultState = {};

export const FORM_ERROR_TYPE = {
  FORM: 'FORM',
  ITEM: 'ITEM',
}

/**
 * The partsTotal price here is already converted to supplier's currency
 * @param {*} items 
 * @param {*} currency 
 * @param {*} exchangeRate 
 * @returns 
 */
const getPartsTotal = (items, currency, exchangeRate) => {
  if (isEmpty(items) || isEmpty(currency)) {
    return 0;
  }
  const partsTotal = items.reduce((acc, item) => {
    if (item.ignoreItem || item.supplierQuoted) {
      return acc;
    }
    const quotation = item.quotations[0];
    const qExchangeRate = getQuotationExchangeRate(item, currency) || exchangeRate;
    if (item.itemQuoteType === ITEM_QUOTE_TYPE.PPE) {
      const { totalPrice } = convertPriceWithQuantityToCurrency({
        totalPrice: quotation.quote,
        quantity: quotation.quantity,
        currency,
        exchangeRate: qExchangeRate,
      });
      return new Decimal(acc).plus(new Decimal(totalPrice)).toNumber();
    }
    const unitQuote = item.itemQuoteType !== ITEM_QUOTE_TYPE.PPE || currency === CURRENCY_CODE.SGD
      ? quotation.unitQuote
      : quotation.unitQuote * qExchangeRate;
    return acc + quotation.quantity * unitQuote;
  }, 0);

  const partsTotalStr = currency
    ? convertPriceToCurrency({
      price: partsTotal,
      currency,
      exchangeRate: 1,
    })
    : 0;
  return partsTotalStr;
}

const getOtherQuoteProperties = (item) => {
  const {
    material,
    customMaterial,
    surfaceFinish,
    customSurfaceFinish,
    materialColor,
    color,
  } = item;
  const properties = {
    material,
    otherMaterial: customMaterial,
    surfaceFinish,
    otherSurfaceFinish: customSurfaceFinish,
    materialColor,
    color,
  }
  return properties;
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case INIT_FORM_STATE: {
      const {
        projectID,
        projectType,
        items: itemDataList,
        currency,
        exchangeRate,
        qcReports
      } = action.payload;
      const items = itemDataList.map(item => {
        const {
          technology,
          material,
          customMaterial,
          surfaceFinish,
          customSurfaceFinish,
          itemQuoteType,
        } = item;
        return {
          itemID: item.itemID,
          name: item.name,
          price: item.price,
          collectionDate: item.collectionDate,
          itemQuoteType,
          referenceName: item.referenceName,
          technology,
          quantity: item.quantity,
          material,
          customMaterial,
          description: item.description,
          surfaceFinish,
          customSurfaceFinish,
          tolerance: item.tolerance,
          cadFile: item.cadFile,
          imageFile: item.imageFile,
          twoDImageUrl: item.twoDImageUrl,
          remarks: '',
          deadline: item.deadline,
          leadTime: item.leadTime,
          metadata: item.metadata,
          color: item.color,
          materialColor: item.materialColor,
          ...item.metadata,
          supplierQuoted: item.supplierQuoted,
          isSupplierQuoteAccepted: item.isSupplierQuoteAccepted,
          exchangeRates: item.exchangeRates,
          instantQuotesLogMeta: item.instantQuotesLogMeta,
          quotations: [{
            id: 0,
            quantity: item.quantity || "",
            quote: itemQuoteType === ITEM_QUOTE_TYPE.PPE ? item.price : "",
            unitQuote: itemQuoteType === ITEM_QUOTE_TYPE.PPE
              ? Number(item.price) / (item.quantity || 1)
              : "",
            remarks: "",
            ...getOtherQuoteProperties(item),
            ...item.metadata,
            exchangeRates: item.exchangeRates,
            cmmPrice: '',
            isCmmReport: qcReports && qcReports.main === QC_FORMAL_CMM_REPORT
          }],
          ignoreItem: false,
          watchingJobStatus: item.watchingJobStatus,
          unitType: item.unitType,
          ppeInformation: item.ppeInformation,
          targetPriceSupplier: item.targetPriceSupplier,
          acceptedQuote: item.acceptedQuote,
          iqLogsID: item.iqLogsID,
          priceFeedback: item.priceFeedback,
          itemQuotationID: item.quotationID,
          supplierQuotations: item.supplierQuotations,
        }
      });
      const initState = {
        projectID,
        projectType,
        items,
        currency,
        exchangeRate,
        partsTotal: convertPriceToCurrency({
          price: 0,
          currency,
          exchangeRate: 1,
        }),
        formError: {},
        qcReports
      }
      if ([PROJECT_TYPE.PPE, PROJECT_TYPE.MIX_PPE_RFQ, PROJECT_TYPE.MIX_TECHNOLOGIES].includes(projectType)) {
        const newPartsTotal = getPartsTotal(items, currency, exchangeRate);
        initState.partsTotal = newPartsTotal;
      }
      return initState;
    }
    case UPDATE_QUOTE_FIELD_VALUE: {
      const {
        itemID,
        quotationID,
        fieldName,
        value,
      } = action.payload;
      const item = state.items.find(item => item.itemID === itemID);
      const quotation = item.quotations.find(quote => quote.id === quotationID);
      quotation[fieldName] = value;
      if ([QUANTITY_STR, UNIT_QUOTE_STR].includes(fieldName)) {
        quotation.quote = (quotation.quantity * quotation.unitQuote).toFixed(2);
        quotation.totalPrice = formulas.calculateDefaultTotalPrice(quotation.quote, state.currency, state.exchangeRate);
        const newPartsTotal = getPartsTotal(state.items, state.currency, state.exchangeRate);
        state.partsTotal = newPartsTotal;
      }
      if (fieldName === MATERIAL_STR && !isCustomMaterial(value)) {
        quotation[OTHER_MATERIAL_STR] = null;
      }
      if (fieldName === SURFACE_FINISH_STR && !isCustomSurfaceFinish(value)) {
        quotation[OTHER_SURFACE_FINISH_STR] = null;
      }
      state.formError = {};
      return {
        ...state,
      }
    }
    case UPDATE_REMARKS_VALUE: {
      const { itemID, value } = action.payload;
      const item = state.items.find(item => item.itemID === itemID);
      item.remarks = value;
      state.formError = {};
      return {
        ...state,
      }
    }
    case ADD_QUOTE_FIELDS: {
      const { itemID } = action.payload;
      const item = state.items.find(item => item.itemID === itemID);
      const lastQuotation = item.quotations[item.quotations.length - 1];
      item.quotations.push({
        id: lastQuotation.id + 1,
        quantity: "",
        quote: "",
        unitQuote: "",
        leadTime: "",
        remarks: "",
        ...getOtherQuoteProperties(item),
        ...item.metadata,
      })
      return {
        ...state,
      };
    }
    case REMOVE_QUOTATION: {
      const { itemID, quotationID } = action.payload;
      const item = state.items.find(item => item.itemID === itemID);
      item.quotations = item.quotations.filter(quotation => quotation.id !== quotationID);
      return {
        ...state,
      };
    }
    case TOGGLE_IGNORE_ITEM: {
      const { itemID, value } = action.payload;
      const item = state.items.find(item => item.itemID === itemID);
      item.ignoreItem = value;
      const newPartsTotal = getPartsTotal(state.items, state.currency, state.exchangeRate);
      state.partsTotal = newPartsTotal;
      state.formError = {};
      return {
        ...state,
      };
    }
    case UPDATE_CURRENCY: {
      const { currency, exchangeRate } = action.payload;
      const newPartsTotal = getPartsTotal(state.items, currency, exchangeRate);
      state.partsTotal = newPartsTotal;
      state.formError = {};
      return {
        ...state,
        currency,
        exchangeRate,
      }
    }
    case UPDATE_WATCHING_JOB: {
      const { itemID, value } = action.payload;
      const item = state.items.find(item => item.itemID === itemID);
      item.watchingJobStatus = value;
      return {
        ...state,
      }
    }
    case UPDATE_ALL_WATCHING_JOB: {
      const { value } = action.payload;
      state.items.forEach((item) => {
        item.watchingJobStatus = value
      })
      return {
        ...state,
      }
    }
    case FORM_ERROR: {
      const { type, message } = action.payload;
      if (type === FORM_ERROR_TYPE.FORM) {
        state.formError.form = message;
      } else if (type === FORM_ERROR_TYPE.ITEM) {
        const { itemID, quotationID, field } = action.payload;
        if (field === REMARKS_STR) {
          set(state.formError, `${itemID}.${field}`, message);
        } else {
          set(state.formError, `${itemID}.${quotationID}.${field}`, message);
        }
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
