import { TECHNOLOGY_OPTION_TYPE } from "../constants/NewPartConstants";

export const isCustomMaterial = (material) => {
  return material === 'Others' || material === 'Custom Material';
}

export const isCustomTechnology = (technology) => {
  return technology === TECHNOLOGY_OPTION_TYPE.OTHERS || technology === 'Custom Fabrication Technology';
}

export const isCustomSurfaceFinish = (surfaceFinish) => {
  return surfaceFinish === 'Others' || surfaceFinish === 'Custom Finish';
}

export const isCustomColor = (color) => {
  return color === 'Others' || color === 'Custom Color';
}

export const isCustomMaterialColor = (materialColor) => {
  return materialColor === 'Others' || materialColor === 'Custom Color';
}

export const validateInput = (input) => {
  if (input < 1) {
    return "1";
  }
  return Math.round(input).toString();
};

/**
 * Checks if the given technology requires dimension information.
 *
 * @param {string} technology - The technology type to check.
 * @returns {boolean} - Returns true if the technology is one of CNC Machining,
 *                      3D Printing, or Sheet Metal Fabrication; otherwise, false.
 */
export const isMainTechnology = (technology) => {
  return [
    TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
    TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
    TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION
  ].includes(technology)
}
